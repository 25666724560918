import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { loading } from "../../actions/page";
import { hasClass } from "../../utils/page";
import Loading from "../common/Loading";

class ActionLink extends Component {
  state = {
    form: this.props.form
  };

  render() {
    let { href } = this.props;
    const {
      loading,
      loadingClassName,
      text,
      loadingText,
      className,
      showLoadingIndicator
    } = this.props;

    return href !== "form_submit" ? (
      <a
        href={href}
        className={`${className} ${loading ? loadingClassName : ""}`}
        onClick={this.handleOnClick}
        disabled={loading}>
        {loading && (
          <Loading showSpinner={showLoadingIndicator}>{loadingText}</Loading>
        )}
        {!loading && text}
      </a>
    ) : (
      <button
        className={`submit ${className} ${loading ? loadingClassName : ""}`}
        onClick={this.handleOnClick}
        disabled={loading}>
        {loading && (
          <Loading showSpinner={showLoadingIndicator}>{loadingText}</Loading>
        )}
        {!loading && text}
      </button>
    );
  }

  handleOnClick = () => {
    if (this.props.loading) return;
    this.props.pageLoading(true);

    if (this.props.href === "form_submit") {
      if (this.state.form) {
        const form = document.querySelector(this.state.form);

        if (form) {
          if (hasClass("body", "firefox")) {
            const clonedForm = form.cloneNode(true);
            clonedForm.style.display = "none";
            document.body.appendChild(clonedForm);
            clonedForm.submit();
          } else {
            form.submit();
          }
        }
      }
      return false;
    }
  };
}

ActionLink.defaultProps = {
  form: "#form",
  loadingText: "",
  showLoadingIndicator: true
};

ActionLink.propTypes = {
  text: PropTypes.string.isRequired,
  loadingText: PropTypes.string,
  href: PropTypes.string.isRequired,
  form: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
  loadingClassName: PropTypes.string,
  loadingIconClass: PropTypes.string,
  form: PropTypes.string,
  showLoadingIndicator: PropTypes.bool
};

const mapStateToProps = (state) => ({
  loading: state.page.loading
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: (status) => dispatch(loading(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionLink);
